<template>
  <v-container v-if="pageLoading" class="bg-color-theme px-3">
    <v-skeleton-loader type="list-item-two-line, card"></v-skeleton-loader>
  </v-container>
  <v-container v-else class="bg-color-theme px-3 cash-sales-main">
    <v-card>
      <v-card-text>
        <h6 class="font-12px text-888 mb-6">{{ todayDate }}</h6>
        <div class="input-select-box mx-auto">
          <v-select
            dense
            solo
            :items="getYears"
            item-text="text"
            item-value="value"
            class="font-15px mb-2"
            background-color="#EAEDF0"
            hide-details
            v-model="year"
            @change="getDataFromApi()"
          ></v-select>
        </div>
        <v-slide-group
          class="slider-box mx-auto mt-1"
          show-arrows="always"
          v-model="month"
          mobile-breakpoint="0"
          @change="getDataFromApi()"
        >
          <v-slide-item
            v-for="(item, index) in getMonths"
            :key="index"
            v-slot="{ active, toggle }"
            :value="item.value"
          >
            <v-btn
              active-class="font-weight-bold"
              :input-value="active"
              small
              text
              @click="toggle"
              plain
              :ripple="false"
              :disabled="loading"
            >
              {{ item.text }}
            </v-btn>
          </v-slide-item>
        </v-slide-group>
        <div class="d-flex justify-center mt-7 light-yellow">
          <span class="font-14px text-333">
            合計現金売上
          </span>
          <h6 class="font-25px text-333 fw-500 ml-6">
            {{ getPriceFormat(totalEarning) }}
          </h6>
        </div>
        <div class="d-flex justify-center mt-9 ml-16 font-12px text-7c fw-700">
          <p class="ml-14 mb-0">
            売上
          </p>
        </div>
        <div class="border-bottom-slider mx-n4 mt-2"></div>
        <div class="cash-sales-table">
          <v-simple-table>
            <tbody>
              <tr v-for="item in days" :key="item.value">
                <td class="cash-sales-column-days">
                  {{ item.text }}
                </td>
                <td class="cash-sales-column-earnings">
                  <span v-if="item.earning != '--'"
                    >{{ getPriceFormat(item.earning) }}
                  </span>
                  <span v-else>{{ item.earning }} </span>
                </td>
              </tr>
            </tbody>
          </v-simple-table>
        </div>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import dayjs from "@/plugins/dayjs";
export default {
  name: "CashSalesList",
  data() {
    return {
      pageLoading: false,
      loading: false,
      year: "",
      month: "",
      todayDate: null,
      days: [],
      totalEarning: 0
    };
  },
  computed: {
    ...mapGetters(["getCashsalesByYearMonth"]),
    getYears() {
      let yearsData = [];
      let today = dayjs();
      let begin = today.subtract(3, "year");
      let finish = today.add(3, "year");

      while (begin.isSameOrBefore(finish)) {
        yearsData.push({
          text: begin.format("YYYY年"),
          value: begin.format("YYYY")
        });
        begin = begin.add(1, "year");
      }
      return yearsData;
    },
    getMonths() {
      let months = [];
      for (let i = 1; i <= 12; i++) {
        months.push({ value: i, text: i + "月" });
      }
      return months;
    }
  },
  created() {
    this.todayDate = dayjs().format("YYYY-MM-DD");
    this.year = dayjs().format("YYYY");
    this.month = parseInt(dayjs().format("MM"));

    this.getDays();

    let this_this = this;
    this.$root.$off("shopChanged");
    this.$root.$on("shopChanged", function() {
      this_this.getDataFromApi();
    });
    this.getDataFromApi();
  },
  methods: {
    getDays() {
      this.days = [];
      let daysInMonth = new Date(this.year, this.month, 0).getDate();
      for (let i = 1; i <= daysInMonth; i++) {
        let dayName = dayjs(this.year + "-" + this.month + "-" + i)
          .locale("ja")
          .format("ddd");
        this.days.push({
          value: i,
          text: this.month + "/" + i + " " + dayName,
          day: dayName,
          earning: "--"
        });
      }
      return this.days;
    },
    getDataFromApi() {
      this.loading = true;
      this.pageLoading = true;
      let data = {
        shop_id: this.$store.getters.getFirstShopObject.id, //Shop Id
        year: this.year,
        month: this.month
      };

      this.$store.dispatch("CASHSALES_GET_BY_YEAR_MONTH", data).finally(() => {
        this.loading = false;
        this.pageLoading = false;
        this.patchEditFormValues();
      });
    },
    patchEditFormValues() {
      this.getDays();
      this.totalEarning = 0;
      if (this.getCashsalesByYearMonth.length > 0) {
        for (let i = 0; i < this.getCashsalesByYearMonth.length; i++) {
          var cashSaleDate = this.getCashsalesByYearMonth[i].report_date.split(
            "-"
          );
          let cashSaleMonth = cashSaleDate[1];
          let cashSaleDay = cashSaleDate[2];

          for (let j = 0; j < this.days.length; j++) {
            if (this.days[j].value == cashSaleDay) {
              this.days[j].earning = this.getCashsalesByYearMonth[i].cash_sales;
              this.totalEarning =
                parseInt(this.totalEarning) +
                parseInt(this.getCashsalesByYearMonth[i].cash_sales);
            }
          }
        }
      }
    },
    getPriceFormat(value) {
      if (typeof value == "string") {
        return this.priceFormat(parseInt(value));
      }
      return this.priceFormat(value);
    }
  }
};
</script>

<style lang="scss" src="./style.scss" scoped></style>
